import axios from 'axios'
export default {
  methods: {
    rec_paises_id (pPaisId) {
      this.loadingDepartamento = true
      this.items.departamentos = []
      const urlroute = this.$store.getters.getUrl + 'departamentos_pais/' + pPaisId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items.departamentos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDepartamento = false
      })
    }

  }
}
