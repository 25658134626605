import axios from 'axios'
export default {
  methods: {
    rec_departamento_id (pDepartamentoId) {
      this.loadingCiudad = true
      this.items.ciudades = []
      const urlroute = this.$store.getters.getUrl + 'ciudades_departamento/' + pDepartamentoId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items.ciudades = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingCiudad = false
      })
    }

  }
}
