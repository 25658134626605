import axios from 'axios'
export default {
  methods: {
    rec_micrositio_por_id (pId) {
      this.loandingQR = true
      this.registro = null
      const urlroute = this.$store.getters.getUrl + 'gestion_id/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.dataReplica = []
        this.registro = response.data
        this.rec_micrositio_data(response.data)
        this.estadoReplica(this.registro)
        // this.$refs.form.resetValidation()
      }).catch(error => {
        this.dataReplica = []
        console.log('error ' + error)
      }).finally(() => {
        this.loandingQR = false
      })
    },
    rec_micrositio_data (data) {
      if (data.gesproducto !== null && data.gesproducto.departamento_id !== null) {
        this.rec_departamento_id(data.gesproducto.departamento_id)
      }
      this.dataReplica = []
      // this.form_qr.keyform = this.rec_funciones_key()
      // this.items.objSubMotivoQR = null
      // this.items.objTipoQR = null
      // this.items.showMarcacion = false

      // SOLICITANTE
      // this.form_solicitante.gestion_id = data.id
      // this.form_solicitante.tipo_id = data.gessolicitante.tipo_id
      // this.form_solicitante.nombre = data.gessolicitante.nombre
      // this.form_solicitante.telefono = data.gessolicitante.telefono
      // this.form_solicitante.correo = data.gessolicitante.correo
      this.form_solicitante.tipo_id = data.gessolicitante.tipo_id
      this.form_solicitante.correo = data.gessolicitante.correo

      // this.form_cliente.gestion_id = 0
      // this.form_cliente.tipo_id = 0
      // this.form_cliente.documento_id = 0
      // this.form_cliente.identificacion = ''
      // this.form_cliente.nombre = ''
      // this.form_cliente.sexo_id = null
      // this.form_cliente.lgbtiq_id = null
      // this.form_cliente.especial_id = null

      this.form_producto.gestion_id = data.id
      this.form_producto.producto_id = data.gesproducto.producto_id
      this.form_producto.productodigital_id = data.gesproducto.productodigital_id
      this.form_producto.numero = data.gesproducto.numero
      this.form_producto.pais_id = data.gesproducto.pais_id
      this.form_producto.departamento_id = data.gesproducto.departamento_id
      this.form_producto.municipio_id = data.gesproducto.municipio_id
      this.form_vehiculo.gestion_id = data.id
      this.form_vehiculo.placa = (data.gesvehiculo) ? data.gesvehiculo.placa : ''

      this.form_qr.motivo_id = data.motivo_id
      this.form_qr.submotivo_id = data.submotivo_id
      this.form_qr.descripcion = data.descripcion

      // this.form_qr.aplicativo_id = 2
      // this.form_qr.id_usuario = null
      // this.form_qr.codigo = 0
      // this.form_qr.tipo_id = 0
      // this.form_qr.instancia_id = 0
      this.form_qr.canal_id = (data.canal_id !== null) ? data.canal_id : null
      // this.form_qr.admision_id = null
      // this.form_qr.entecontrol_id = null
      // this.form_qr.quejaexpres_id = null
      // this.form_qr.descripcion = ''
      // this.form_qr.fecha_requerimiento = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      // this.form_qr.segundos = 0
      // this.form_qr.subestado_id = 1
      // this.form_qr.escalado_id = 1
      // this.form_qr.fechacerrado = null
      // this.form_qr.diasint = 0
      // this.form_qr.diasext = 0
      // this.form_qr.fechaparacerrarexterno = null
      // this.form_qr.fechaparacerrarinterno = null
      // this.form_qr.area = null

      // this.form_duplicado.gestion_id = 0
      // this.form_duplicado.duplicado_id = 0
      // this.form_duplicado.duplicadomotivo_id = null
      this.form_duplicado.reincidente_id = (data.gesduplicado.reincidente !== null) ? data.gesduplicado.reincidente : null
      this.form_duplicado.canaoriginol_id = (data.gesduplicado.canaoriginol !== null) ? data.gesduplicado.canaoriginol : null
      // this.form_duplicado.marcacion_id = null

      // this.form_files.items_archivos = []
    },
    rec_micrositio_datos () {
      this.form_solicitante.gestion_id = this.gestion_id
      this.form_producto.gestion_id = this.gestion_id
      this.form_vehiculo.gestion_id = this.gestion_id
      this.form_duplicado.gestion_id = this.gestion_id
      this.form_qr.id_usuario = this.session.id

      // DEFINIR SI ES DUPLICADO O RADICADO O ESCALADO
      this.form_qr.subestado_id = 2
      this.form_qr.escalado_id = 2
      if (this.form_duplicado.duplicado_id === 29) {
        // DUPLICADO
        this.form_qr.subestado_id = 4
        this.form_qr.escalado_id = 4
      } else if (this.form_producto.producto_id === 4 || this.form_producto.producto_id === 1) {
        // USUARIOS - ODT
        this.form_qr.subestado_id = 2
        this.form_qr.escalado_id = 2
      } else if ((this.form_qr.tipo_id === 76 || this.form_qr.tipo_id === 77) && this.form_qr.motivo_id === 52) {
        // FRAUDE
        this.form_qr.subestado_id = 2
        this.form_qr.escalado_id = 2
      }
    },
    rec_micrositio_guardar () {
      this.rec_micrositio_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      const urlroute = this.$store.getters.getUrl + 'gestion_micrositio'
      const data = { codigo: this.registro.codigo, solicitante: this.form_solicitante, gestion_id: this.gestion_id, gestion: this.form_qr, producto: this.form_producto, duplicado: this.form_duplicado, vehiculo: this.form_vehiculo, cliente: this.registro.gescliente }

      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.registro = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    eliminarMarcacionJS (pId) {
      this.borrarMarcacion = false
      this.loandingQR = true
      const urlroute = this.$store.getters.getUrl + 'eliminarMarcacion/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.rec_micrositio_por_id(pId)
        this.esperar = false
      })
    }

  }
}
