<template>
<div style="width:100%">
<div class="text-center" v-if="loandingQR">
  <br><br>
  <v-progress-circular :size="150" :width="7" :color="colores.primario" indeterminate></v-progress-circular>
  <br><br><br><br>
</div>
<div v-if="!loandingQR">
    <buscar :session="session" :rules="rules" :items="items" :loadingData="loadingData" :colores="colores"></buscar>
    <div v-if="registro && registro.subestado_id !== 7">
        <lectura :gestion_id="gestion_id" :session="session" :token="tokenInbound" :colores="colores" :items="items"></lectura>
    </div>
    <div v-else>
        <br><br>
          <lecturaHead v-if="registro" :registro="registro" :colores="colores" :dataReplica="dataReplica"></lecturaHead>
        <br>
        <v-form ref="form">
          <solicitante :form_solicitante="form_solicitante" :registro="registro" :rules="rules" :items="items" :loadingData="loadingData" :colores="colores"></solicitante>
          <br>
          <lecturaCliente v-if="registro" :registro="registro" :colores="colores"></lecturaCliente>
          <br>
          <producto v-if="registro" :form_producto="form_producto" :form_vehiculo="form_vehiculo" :rules="rules" :items="items" :loadingData="loadingData" :colores="colores"></producto>
          <br>
          <qr v-if="registro" :form_qr="form_qr" :registro="registro" :session="session" :form_files="form_files" :form_duplicado="form_duplicado" :rules="rules" :items="items" :loadingData="loadingData" :colores="colores" :dataReplica="dataReplica"></qr>
          <br>
        </v-form>
        <v-row no-gutters v-if="session.area !== 17 && this.session.id === this.idEscalado">
          <v-col cols="12" class="text-center">
            <v-btn @click="validar" block large :color="colores.black" dark>GUARDAR</v-btn>
          </v-col>
        </v-row>
        <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
        <br><br><br><br>
    </div>
  </div>
</div>
</template>

<script>
import recMicrositios from '@/js/rec_micrositios.js'
import recFunciones from '@/js/rec_funciones.js'
import recDepartamentos from '@/js/rec_departamentos.js'
import recPaises from '@/js/rec_paises.js'
// import buscar from '@/components/micrositio/buscarComponent.vue'
// import lectura from '@/components/crm/lecturaComponent.vue'
// import lecturaHead from '@/components/crm/lectura_headComponent.vue'
// import lecturaSolicitante from '@/components/crm/lectura_solicitanteComponent.vue'
// import lecturaCliente from '@/components/crm/lectura_clienteComponent.vue'
// import producto from '@/components/crm/form_productoComponent.vue'
// import qr from '@/components/micrositio/form_qrComponent.vue'
// import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'

export default {
  name: 'micrositio_formComponent',
  components: {
    buscar: () => import('@/components/micrositio/buscarComponent.vue'),
    lectura: () => import('@/components/crm/lecturaComponent.vue'),
    lecturaHead: () => import('@/components/crm/lectura_headComponent.vue'),
    lecturaCliente: () => import('@/components/crm/lectura_clienteComponent.vue'),
    solicitante: () => import('@/components/sfc/form_solicitanteComponent.vue'),
    producto: () => import('@/components/crm/form_productoComponent.vue'),
    qr: () => import('@/components/micrositio/form_qrComponent.vue'),
    mensajesSave: () => import('@/components/widgets/mensajes_saveComponent.vue')
  },
  props: ['colores', 'items', 'loadingData', 'session', 'tokenInbound', 'gestion_id'],
  mixins: [recMicrositios, recFunciones, recPaises, recDepartamentos],
  data: () => ({
    rules: null,
    loandingQR: false,
    registro: null,
    form_solicitante: { gestion_id: 0, tipo_id: 0 },
    form_producto: { gestion_id: 0, producto_id: 0, productodigital_id: 0, numero: '', pais_id: 0, departamento_id: 0, municipio_id: 0 },
    form_vehiculo: { gestion_id: 0, placa: '' },
    form_qr: { aplicativo_id: 1, keyform: 0, id_usuario: null, codigo: 0, tipo_id: 0, instancia_id: 0, canal_id: null, admision_id: null, entecontrol_id: null, motivo_id: 0, submotivo_id: 0, quejaexpres_id: null, descripcion: '', fecha_requerimiento: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), segundos: 0, subestado_id: 2, escalado_id: 1, fechacerrado: null, id_usuariocerro: 0, fechaparacerrarexterno: null, fechaparacerrarinterno: null, fechaparacerrarprorroga: null, fechaparacerrarexpres: null, diasext: 0, diasint: 0, area: null },
    form_duplicado: { gestion_id: 0, duplicado_id: 0, duplicadomotivo_id: null, reincidente_id: 0, canaoriginol_id: null, marcacion_id: null, codigo: 0, correoenvio_id: 0 },
    form_files: { items_archivos: [] },
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: [],
    idEscalado: '',
    tempMicro: 0,
    dataReplica: []
  }),
  created () {
    this.rules = this.$store.getters.getRules
    this.startInterval()
  },
  watch: {
    tokenInbound: {
      immediate: true,
      handler (newVal, oldVal) {
        this.rec_micrositio_por_id(this.gestion_id)
      }
    }
  },
  methods: {
    validar () {
      if (this.$refs.form.validate()) {
        this.dialogo.pregunta = true
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_micrositio_guardar()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    startInterval () {
      this.idSetIntervalQR = setInterval(() => {
        if (this.registro !== null && 'escalamientos' in this.registro) {
          for (let i = 0; i < this.registro.escalamientos.length; i++) {
            if (this.registro.escalamientos[i].fecha_cerrado === null) {
              this.idEscalado = this.registro.escalamientos[i].id_escaladoa
            }
          }
        }
        this.tempMicro += 1
        if (this.tempMicro >= 11) {
          this.endInterval()
        }
      }, 5000)
    },
    endInterval () {
      clearTimeout(this.idSetIntervalQR)
      return null
    },
    estadoReplica (registro) {
      this.dataReplica = []
      for (let i = 0; i < registro.replicas.length; i++) {
        if (registro.replicas.length > 0) {
          this.dataReplica[i] = registro.replicas[i]
        }
      }
    }
  }

}
</script>
